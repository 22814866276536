import BasePage from "./CommonPages/BasePage";
import { useState, useEffect } from "react";
import { Link,useHistory } from 'react-router-dom';
import { renService } from "../../Services/renService";
import { dynamoRenService } from "../../Services/DynamoRenService";
import RenResultsTable from "../Common/RenResultsTable";
import RenDynamoResultsTable from "../Common/RenDynamoResultsTable";
import SearchBar from "../Common/SearchBar";
import TimeFrameSelector from "../Common/CalendarPicker";
import {RenStudentDetailPageColumns,RenStudentPageColumns, RenDynamoStudentPageColumns } from "../Common/columns";
import {formatTime,last12Months} from '../../utils/dateutils'; 

export default function RenStudentPage(props) { 
  const [org, setOrg] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);  
  const [query, setQuery] = useState({
    searchName: "",
    StartDate:formatTime(last12Months(),"MM/DD/YYYY"), 
    EndDate:formatTime(new Date(),"MM/DD/YYYY"),  
    valid:true,
    searched:false,
    reload:false,
    timeFrameChanged:false
  });
  const history = useHistory();
  const handleSearch = (value) => { 
    let searched=query.searchName!==value;
    setQuery({...query,searchName:value.searchName,valid:value.valid,searched:searched,reload:value.reload}); 
  }; 

  const handleDateSelection=(startDate, endDate,selected)=>{
    let strtDt=formatTime(startDate,"MM/DD/YYYY");
    let endDt=formatTime(endDate,"MM/DD/YYYY");
    let timeFrameChanged = (query.StartDate!==strtDt)||(query.EndDate!==endDt); 
    setQuery({...query,StartDate:strtDt,EndDate:endDt,timeFrameChanged:timeFrameChanged});
  };
 
  const validator = (value) => { 
    return true; 
  }; 
  
  useEffect(() => {
    if(props.location.state){     
    setOrg(props.location.state.data);    
    setIsLoaded(true);
    }else{
      history.push('/');
    }

  }, [props.location.state,history]);
  return (
    <BasePage>
      <div label="NWEA (IL Math, MyPathK12)">Not available for this Organization Code</div>
      <div label="NWEA (No Products)">Not available for this Organization Code</div>
      <div label="Renaissance (MyPathK12)">
        <div>
          <p>
            <Link to="/">District Dashboard</Link> &gt; Renaissance Results for {org.id} <span>(Renaissance Client ID: {org.clientid})</span>
          </p>
          <SearchBar
            onSearchClick={handleSearch}
            validator={validator}
            placeholderText="Search Organization by Student ID"
          />
          <TimeFrameSelector
            onTimeFrameChange={handleDateSelection}
            setDefaultValue="Last 12 Months"
          />
          <RenResultsTable
            columns={RenStudentPageColumns}
            detailColumns={RenStudentDetailPageColumns}
            showDetailHeaders={false}
            isLoading={!isLoaded}
            syncKey="Student"
            tooltiptext="Show Additional Tests for Student"
            query={{ ...query, org: org }}
            GetService={renService.summaryStudentAssessments}
            DetailService={renService.summaryStudentAssessments}
          />
        </div>
      </div>
      { <div label="Renaissance (No Products)">
        <div>
          <p>
          <Link to="/">District Dashboard</Link> &gt; Renaissance Results for {org.id} <span>(Renaissance Client ID: {org.clientid})</span>
          </p>
          <SearchBar
            onSearchClick={handleSearch}
            validator={validator}
            placeholderText="Search Organization by Student ID"
          />
          <TimeFrameSelector
            onTimeFrameChange={handleDateSelection}
            setDefaultValue="Last 12 Months"
          />
          <RenDynamoResultsTable
            columns={RenDynamoStudentPageColumns}
            detailColumns={RenStudentDetailPageColumns}
            showDetailHeaders={false}
            isLoading={!isLoaded}
            syncKey="Student"
            tooltiptext="Show Additional Tests for Student"
            query={{ ...query, org: org }}
            GetService={dynamoRenService.summaryStudentAssessments}
            DetailService={dynamoRenService.summaryStudentAssessments}
          />
        </div>
      </div> }
    </BasePage>
  );
}