import ClickableCell  from './ClickableCell';
import MultiLineDataCell  from './MultiLineDataCell';
export const RenDistrictPageColumns =  [   
  {
    title: "ORGANIZATION RPID",
    field: "rpid", 
    align:'left',
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:true,channel:"REN"}} className={"tableLink"}/>,
    headerStyle: { textAlign: 'left' }
  },
  {
    title: "RENAISSANCE CLIENT ID",
    field: "clientid",
    align: "left",
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:true,isName:false,channel:"REN"}} className={"tableLink"}/>,
    headerStyle: { textAlign: 'left' }
  },
  {
    title: "District Name",
    field: "districtName",
    align: "left",
    render: (rowData) =>  <ClickableCell data={{...rowData,isDistrictName: true, channel:"REN"}} className={"tableLink"}/>,
    headerStyle: { textAlign: 'left' }
  },
  {
    title: "Date Added",
    field: "createdon",
    align:'left',      
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:false,channel:"REN"}} className={"tableLink"}/>,
    headerStyle: { textAlign: 'left' }
  }, 
];

export const RenStudentPageColumns =  [   
  {
    title: "STUDENT ID",
    field: "rid", 
    align:'left',
    headerStyle: { textAlign: 'left' }
  },
  {
    title: "Test Date",
    field: "testdate",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }, 
  {
    title: "Import Date",
    field: "importdate",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }, 
  {
    title: "Test",
    field: "test",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }, 
  {
    title: "Score",
    field: "score",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }
];
export const RenDynamoStudentPageColumns =  [   
  {
    title: "STUDENT ID",
    field: "studentId", 
    align:'left',
    headerStyle: { textAlign: 'left' }
  },
  {
    title: "STUDENT REN ID",
    field: "vendorStudentId", 
    align:'left',
    headerStyle: { textAlign: 'left' }
  },
  {
    title: "Test Date",
    field: "testdate",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }, 
  {
    title: "Import Date",
    field: "importdate",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }, 
  {
    title: "Test",
    field: "test",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }, 
  {
    title: "Score",
    field: "score",
    align:'center',
    headerStyle: { textAlign: 'center' }
  },
  {
    title: "ASSESSMENT ID",
    field: "studentAssessmentId",
    align:'center',
    headerStyle: { textAlign: 'center' }
  }
];

export const RenStudentDetailPageColumns =  [   
  {
    title: "PlaceHolder",
    field: "PlaceHolderId", 
    align:'left',   
  },
  {
    title: "TEST DATE",
    field: "testdate",
    align:'center',      
  }, 
  {
    title: "IMPORT DATE",
    field: "importdate",
    align:'center',        
  }, 
  {
    title: "TEST",
    field: "test",
    align:'center',      
  }, 
  {
    title: "SCORE",
    field: "score",
    align:'center',       
  }, 
   
];
export const NweaDistrictPageColumns =  [   
    {
      title: "DISTRICT/SCHOOL",
      field: "name", 
      align:'left',
      render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:true,channel:"NWEA"}} className={"tableLink"}/>,     
    },
    {
      title: "BID",
      field: "rid",
      align:'left',      
      render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:false,channel:"NWEA"}} className={"tableLink"}/>,  
    }, 
 ];  
 export const NweaDistrictDetailsColumns =  [ 
  {
    title: "DISTRICT/SCHOOL",
    field: "name", 
    align:'left' ,
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:true,isName:true,channel:"NWEA"}} className={"tableLink"}/>,   
  },    
  {
    title: "BID",
    field: "rid",
    align:'left',
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:true,isName:false,channel:"NWEA"}} className={"tableLink"}/>,
  }, 
];

export const DynamoNweaDistrictPageColumns =  [   
  {
    title: "DISTRICT/SCHOOL",
    field: "name", 
    align:'left',
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:true,channel:"NWEA-Dynamo"}} className={"tableLink"}/>,     
  },
  {
    title: "BID",
    field: "rid",
    align:'left',      
    render: (rowData) =>  <ClickableCell data={{...rowData,isDetail:false,isName:false,channel:"NWEA-Dynamo"}} className={"tableLink"}/>,  
  }, 
];

export const DynamoNweaDistrictDetailsColumns =  [ 
  {
    title: "DISTRICT/SCHOOL",
    field: "name", 
    align:'left' ,
    render: (rowData) =>  <ClickableCell data={{...rowData, myData:rowData, isDetail:true, isName:true, channel:"NWEA-Dynamo"}} className={"tableLink"}/>,   
  },    
  {
    title: "BID",
    field: "rid",
    align:'left',
    render: (rowData) =>  <ClickableCell data={{...rowData, myData:rowData, isDetail:true, isName:false, channel:"NWEA-Dynamo"}} className={"tableLink"}/>,
  }
]; 

export const NweaStudentPageColumns=[
  {
    title: "SCHOOL",
    field: "schoolname", 
    align:'left'  
  
  },
  {
    title: "STUDENT",
    field: "name",
    align:'center'        
  },   
    {
      title: "NWEA ID (APP)",
      field: "rid", 
      align:'center',
    
    },
    {
      title: "STUDENT BID",
      field: "studentBid",
      align:'left',        
    },
    
  ];

  export const DynamoNweaStudentPageColumns=[    
    {
      title: "SCHOOL",
      field: "schoolId", 
      align:'left'  
    
    },
    {
      title: "STUDENT",
      field: "name",
      align:'center'        
    },   
    {
      title: "NWEA ID (APP)",
      field: "studentId", 
      align:'center',
    
    },
    {
      title: "STUDENT BID",
      field: "vendorStudentId",
      align:'center',        
    }
  ];

export const NweaStudentDetailsColumns=[
      {
        title: "SCHOOL BID",
        field: "schoolBid",
        align:'center',        
        cellStyle:{verticalAlign:'baseline'}      
      },
      {
        title: "TEST DATE",
        field: "testDate",
        align:'center',        
        cellStyle:{verticalAlign:'baseline'}        
      },
      {
        title: "TEST TAKEN",
        field: "testName",
        align:'center',  
        render: (rowData) =><MultiLineDataCell cellData={rowData.testName} additionalInfo={rowData.IANames}/>     
      },
      {
        title: "RIT SCORE",
        field: "rit",
        align:'center',        
        render: (rowData) =><MultiLineDataCell cellData={rowData.rit} additionalInfo={rowData.IAScores} noStyle={true}/>    
      },
      {
        title: "IMPORT DATE",
        field: "importDate",
        align:'center',               
        cellStyle:{verticalAlign:'baseline'}              
      },
];

export const DynamoNweaStudentDetailsColumns=[
  {
    title: "SCHOOL BID",
    field: "schoolId",
    align:'center',        
    cellStyle:{verticalAlign:'baseline'}      
  },
  {
    title: "TEST DATE",
    field: "testDate",
    align:'center',        
    cellStyle:{verticalAlign:'baseline'}        
  },
  {
    title: "TEST TAKEN",
    field: "testName",
    align:'center',  
    render: (rowData) =><MultiLineDataCell cellData={rowData.testName} additionalInfo={rowData.IANames}/>     
  },
  {
    title: "RIT SCORE",
    field: "rit",
    align:'center',        
    render: (rowData) =><MultiLineDataCell cellData={rowData.rit} additionalInfo={rowData.IAScores} noStyle={true}/>    
  },
  {
    title: "IMPORT DATE",
    field: "importDate",
    align:'center',               
    cellStyle:{verticalAlign:'baseline'}              
  },
]


/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
  export default  {
      NweaDistrictPageColumns,
      NweaDistrictDetailsColumns,
      DynamoNweaDistrictPageColumns,
      DynamoNweaDistrictDetailsColumns,
      NweaStudentPageColumns,
      DynamoNweaStudentPageColumns,
      NweaStudentDetailsColumns,
      DynamoNweaStudentDetailsColumns,
      RenDistrictPageColumns,
      RenStudentDetailPageColumns,
      RenStudentPageColumns
    };