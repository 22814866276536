export const CacheKey = (cache)=>{
  return `Name:${cache.Name}$Key:${cache.id}$Param:${cache.Params.Page}${cache.Params.PageSize}`;
}
export const CacheService = {
  PutCacheData: async (cache) => {
    if (cache.Data && cache.Data.values.length > 0) //Avoid caching empty data for dynamic fetch
     {
      const data = JSON.stringify(cache.Data);
      localStorage.setItem(CacheKey(cache), data);
    }
  },
  GetCacheData: async (cache) => {
    var key = CacheKey(cache);

    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      return JSON.parse(cachedData);
    } else {
      return null;
    }
  },
  ClearCacheData: async () => {
    localStorage.clear();
  },
};