import {AISAPIV4,FetchJsonDynamo,setRPIDResultsV4,setRenStudentResultsv4, getRenStudentDetailRow} from './Helper';

export const dynamoRenService = { 
    searchDistrictsAndSchools: async (req) => {    
        let url=`${AISAPIV4}/districts${req.id.length<=0?"":"/"+req.id}?vendortype=Renaissance${req.params.pageSize!==undefined?"&pageLimit="+req.params.pageSize:""}${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`; 
        return FetchJsonDynamo(url).then((resp) => {     
          return { ...resp, values: setRPIDResultsV4(resp.values) };
        });
      },  
    summaryStudentAssessments: async (req) => {    
        let urlpart = "/";    
        if (req.params.searchName.length > 0 ) {
          urlpart = `/students/${req.params.searchName}/`;
          if(req.page===req.currentPage){
             req.page = 1;
          }      
        }
        let url = `${AISAPIV4}/districts/${req.params.org.id}${urlpart}assessments?startDate=${req.params.StartDate}&endDate=${req.params.EndDate}${req.params.pageSize!==undefined?"&pageLimit="+req.params.pageSize:""}${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`;
        return FetchJsonDynamo(url).then((resp) => {
          return {
            ...resp, 
            values: setRenStudentResultsv4(resp.values),
            detail: getRenStudentDetailRow(resp.values),
          };
        });
      }, 
      getStudentAssessments: async (req) => {
        let searchName=req.params.searchName.length>0?req.params.searchName:req.id; 
        let url=`${AISAPIV4}/organizations/${req.params.org.id}/students/${searchName}/assessments?startDate=${req.params.StartDate}&endDate=${req.params.EndDate}${req.paginationToken!==undefined?"&paginationtoken="+req.paginationToken:""}`;   
        return FetchJsonDynamo(url).then((resp) => {
          return { ...resp, values: setRenStudentResultsv4(resp.values,1) };
        });
      },  
}