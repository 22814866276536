import MaterialTable from "@material-table/core";
import { useState, createRef, useEffect, useRef } from 'react';
import { tableIcons } from "./TableProperties";
import { getRowActions } from "./DialogProperties";
import { DataNotFoundMessage } from "../../utils/TableUtils";
//Table element for schools in a district
export const DynamoDetailTable = (props) => {
  
  const [isLoaded, setIsLoaded] = useState(false);  
  const [paging,setPaging]=useState(false); //Determines if enough schools are in a district to show paging
  const [apiData,setApiData] =useState([]);  
  const [tblRowCount,setTblRowCount]=useState(5);
  const [timeoutError,setTimoutError]=useState(false);
  const currentpageToken=useRef(undefined);
  const [pageToken,setPaginationToken]=useState(undefined);
  const tblRef = createRef();
  var isMounted = useRef(true); 
  const {rowData,query,isSchool,DetailService}=props;
  const {districtId,studentId,rid,name}=rowData;
  const {searchName,reload}=query;
  const handlePageChange=(nextPage,rowCount)=>{
    setTblRowCount(rowCount);
    let recordNumber=(nextPage+1)*rowCount;   
    if(recordNumber>2000 && currentpageToken.current!==pageToken){
        setPaginationToken(currentpageToken.current);
    }
    else{
        setPaginationToken(undefined);
    }
  }
  useEffect(() => {
    let selectedId = studentId||searchName;
    const getReq=()=>{ 
        return  {
          id: selectedId,
          districtId: districtId,    
          isSchool: isSchool,   
          params: query,
          dtName: name
        };
      }

      const OnQueryChange=() =>
      new Promise((resolve, reject) => {    
        let req = getReq();
          DetailService(req).then((result) => {
            setIsLoaded(true);            
            setPaging(result.size>tblRowCount) ;
            setTimoutError(result.timeout);
            currentpageToken.current = result.paginationtoken;
            resolve(setApiData(result.values));
          });    
      })
    
    OnQueryChange();
    
    
    return () => {
      isMounted.current = false; 
      
    };
 },[DetailService, districtId, isSchool, name,  query, rid, searchName, studentId,reload,tblRowCount,pageToken]);

  let temp = (
    <div>      
      <MaterialTable
        className="DetailTbl"
        localization={{
          body: {
            emptyDataSourceMessage: (
                <DataNotFoundMessage timeoutError={timeoutError}/>
            ),
          },
        }}
        tableRef={tblRef}
        isLoading={!isLoaded}
        columns={props.columns}
        icons={tableIcons}
        detailPanel={[
          {
            icon: () => <div className="hiddenColumn" />,
            render: () => {},
          },
        ]}
        options={{
          header: props.showHeaders ?? false,
          showTitle: false,
          sorting:undefined,
          maxColumnSort:0,
          toolbar: false,
          columnResizable:false,
          draggable:false,
          paging: paging,
          pageSize: 5,
          pageSizeOptions: [5, 15, 25],
          showFirstLastPageButtons: false,
          paginationType: 'stepped',
          rowStyle: (x) => {        
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f2f2f2" };
            }
          },
          headerStyle: {
            backgroundColor: "#d8edf0",
            color: "black",
            textAlign: "center",
            fontWeight: 300,
          },        
          actionsColumnIndex: -1,
        }}
        data={apiData}
        onPageChange={handlePageChange} 
        actions={getRowActions({ ...props})}        
        onRowClick={(event, rowData, togglePanel) => {  if(event.clientX===0 && event.clientY===0){togglePanel()}}}            
      />
    </div>
  );
  return temp
};

export default DynamoDetailTable;