import React, { useState, useEffect,useContext }  from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import {DataContext} from "./DataContext";
import HelpMenu from "./HelpMenu";

function Header() {
  const { oktaAuth, authState } = useOktaAuth();
  const hasLoggedIn=authState&&authState.isAuthenticated;
  const context=useContext(DataContext);

  const login = async () => { 
    await context.ClearCacheData();
    await oktaAuth.signInWithRedirect(); 
  }
  const logout = async () => { 
    await context.ClearCacheData();
    context.setUserInfo(null);
    await oktaAuth.signOut();
   }
  const [infoLoaded, setinfoLoaded] = useState(false);
  
  useEffect(() => {    
    if(!infoLoaded){     
      if(authState&&authState.isAuthenticated){     
       setinfoLoaded(authState&&authState.isAuthenticated)
       oktaAuth.getUser().then(info => {                    
        context.setUserInfo({email:info.email,name:info.name})
      });}
    }
  }, [authState, oktaAuth,context,infoLoaded]);
  // Sample Logic to check the claims
  const Alpha= hasLoggedIn && authState.accessToken?.claims?.AlphaUser;
  const Beta= hasLoggedIn && authState.accessToken?.claims?.BetaUser;
  const Basic=hasLoggedIn && !(Alpha||Beta);
  var roles=[];
  if(Alpha) {
    roles.push("Alpha");
  }
  if(Beta) {
    roles.push("Beta");
  }
  
  const userdetails= hasLoggedIn?<div>{context.userInfo && (<p>{context.userInfo.name}({context.userInfo.email}) {Basic?<></>:<span><br/>(**Roles:{roles.join(',')})</span>}</p>)}</div> :<div></div>;
  const logonAction = hasLoggedIn
  ? <button onClick={ logout }>Logout</button>
  : <button onClick={ login }>Sign In</button>;
  return (
    <header>          
      <ul className="menu">
        <li><Link to="/" onClick={() => window.location.reload()}>Home</Link></li>
      </ul>
      <div className="headerTitle">Assessment Integration Services</div>
      <div className="userDetails">
        {userdetails}
      </div>
      <div className='homeBtnDiv'>
        <div className="logoutButton">
          {logonAction}
        </div>
        <HelpMenu />
      </div> 
    </header>
  );
}
export default Header;