import React from "react";
import ReactDOM from "react-dom";
import "./styles/chartist.min.css";
import "./styles/chartist-plugin-tooltip.css";
import "./styles/App.css";
import "./styles/index.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import {DataProvider} from "./Components/Common/DataContext";
import Loading from "./Loading";
import ErrorBoundary from "./ErrorBoundary";
const isProduction = process.env.NODE_ENV === "production";
ReactDOM.render(
<ErrorBoundary>
  <DataProvider>
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
     loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
  >
    <Router>
      <App />
    </Router>
  </CacheBuster>
  </DataProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
