import MaterialTable from "@material-table/core";
import { useState, createRef, useEffect, useRef } from 'react';
import { tableIcons } from "./TableProperties";
import { getRowActions } from "./DialogProperties";

//Table element for schools in a district
export const DetailsTable = (props) => {
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentPage,setCurrentPage]=useState(0);
  const [currentPageSize,setcurrentPageSize]=useState(5);
  const [paging,setPaging]=useState(false); //Determines if enough schools are in a district to show paging
  const [prevSearch,setPrevSearch]=useState('');
  const tblRef = createRef();
  var isMounted = useRef(true); 
  const getReq=(query,props)=>{ 
    let tmpid=props.rowData.districtBid||props.rowData.studentBid?props.rowData.rid:props.query.searchName;
    return  {
      id: tmpid.length>0?tmpid:props.rowData.rid,
      page: query.page + 1,
      pageSize: query.pageSize,
      isSchool: props.isSchool,   
      params: props.query,
      dtName: props.rowData.name
    };
  }
  const NeedsToLoad=(query)=>{
    return  (isMounted.current 
    ||currentPage < query.page + 1 
    ||currentPageSize < query.pageSize
    ||currentPageSize>  query.pageSize  
    ||currentPage > query.page + 1 );
  }
  useEffect(() => {    
    if (props.dialogStatus) {      
      return;
    }
    if(prevSearch!==props.query.searchName){
      setPrevSearch(props.query.searchName);
      tblRef.current && tblRef.current.onQueryChange(); 
    }
    
    return () => {
      isMounted.current = false;   
      setCurrentPage(0)  ;
      setcurrentPageSize(5);
      
    };
 },[tblRef,paging, currentPageSize,currentPage,props.dialogStatus,props.query.reload,props.query.searchName,prevSearch])

  let temp = (
    <div>      
      <MaterialTable
        className="DetailTbl"
        localization={{
          body: {
            emptyDataSourceMessage: (
              <h3 className="tableNotFoundHdr">No data found</h3>
            ),
          },
        }}
        tableRef={tblRef}
        isLoading={!isLoaded}
        columns={props.columns}
        icons={tableIcons}
        detailPanel={[
          {
            icon: () => <div className="hiddenColumn" />,
            render: () => {},
          },
        ]}
        options={{
          header: props.showHeaders ?? false,
          showTitle: false,
          sorting:undefined,
          maxColumnSort:0,
          toolbar: false,
          columnResizable:false,
          draggable:false,
          paging: paging,
          pageSize: currentPageSize,
          pageSizeOptions: [5, 15, 25],
          rowStyle: (x) => {        
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f2f2f2" };
            }
          },
          headerStyle: {
            backgroundColor: "#d8edf0",
            color: "black",
            textAlign: "center",
            fontWeight: 300,
          },        
          actionsColumnIndex: -1,
        }}
        data={(query) =>
          new Promise((resolve, reject) => {           
            if (NeedsToLoad(query))
             {
              let req = getReq(query,props);
              props.DetailService(req).then((result) => {
                setIsLoaded(true);
                setCurrentPage(result.page);
                setcurrentPageSize(result.pageSize);
                setPaging(result.size>1);               
                resolve({
                  data: result.values,
                  page: result.page - 1,
                  totalCount: result.size * result.pageSize,
                });
              });
            } else {
              setIsLoaded(true);
            }
          })
        }
        actions={getRowActions({ ...props})}        
        onRowClick={(event, rowData, togglePanel) => {  if(event.clientX===0 && event.clientY===0){togglePanel()}}}            
      />
    </div>
  );
  return temp
};

export default DetailsTable;