import React from "react";
import Tabs from "./Tabs";

function BasePage(props) {
  return (
    <div className="DistrictPage">
      <div className="container">
          <div className="col-lg-11">
          <Tabs> 
              {props.children}
            </Tabs>     
        </div>
      </div>
    </div>
  );
}

export default BasePage;