import  { useState }  from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import config from "../../config/baseconfig";

export default function HelpMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {    
    setAnchorEl(event.currentTarget);
  };
  const handleItemClick = (event) => {
    let action=event.target.ariaValueText;    
    switch(action){
      case "release":
        window.open(config.ReleaseNotes);
        break;
      case "nwea":
          window.open(config.NweaDocumentation);
          break;
      case "ren":
          window.open(config.RenaissanceDocumentation);
          break;
      case "feedback":
          window.open(config.SlackChannel);
          break;  
        default:
          break;
    }
    
    setAnchorEl(null);
  };
  const handleClose = () => {    
    setAnchorEl(null);
  };
  return (
    <div className='helpBtnDiv'>
      <HelpOutlineIcon id="help-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
        onClick={handleClick} />
      <Menu id="basic-menu" anchorEl={anchorEl} open={open}  onClose={handleClose} MenuListProps={{'aria-labelledby': 'help-button',}}>
        <MenuItem onClick={handleItemClick} aria-valuetext="release">Release Notes</MenuItem>
        <MenuItem onClick={handleItemClick} aria-valuetext="nwea">NWEA Documentation</MenuItem>
        <MenuItem onClick={handleItemClick} aria-valuetext="ren">Renaissance Documentation</MenuItem>
        <MenuItem onClick={handleItemClick} aria-valuetext="feedback">Feedback</MenuItem>
      </Menu>
    </div>
  );
}
