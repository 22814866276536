import config from './baseconfig';
const OKTA_TESTING_DISABLEHTTPSCHECK =  (window as any).APP_ENV.oktadisableHttpsCheck;
// eslint-disable-next-line
export default {
  oidc: {
    clientId: config.OktaClientId,
    issuer: config.OktaURL +'/oauth2/default',
    redirectUri: window.location.origin + '/login/callback',
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    scopes:['openid','profile','email','permissions']  
  }
};