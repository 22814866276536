import React, { createContext, useState } from "react";
import { CacheService } from "../../Services/CacheService";

export const DataContext = createContext();

// This context provider is passed to any component requiring the context
export const DataProvider = ({ children }) => {

  const [userInfo, setUserInfo] = useState({ email: "", name: "" });
  const ClearCacheData=async ()=>{
    await CacheService.ClearCacheData();
  }

  const GetBodyLabel=(action, prp)=>{ 
    switch(action){
      case "SyncNweaDistricts":
      case "AllDistrict":
        return "Are you sure you want to sync all districts with data sharing agreements?";
      case "School::2":
      case "SyncNweaDynamoSchools::3":
      case "SyncNweaDynamoStudents::2":
        return "Are you sure you want to sync all student assessments within ["+prp+"]?";      
      case "District::1":
      case "School::1":
      case "SyncNweaDynamoSchools::1":
      case "SyncNweaDynamoStudents::1":
        return "Are you sure you want to sync all students within ["+prp+"]?";
      case "District::2":
      case "SyncNweaDynamoSchools::2":
        return "Are you sure you want to sync all schools within ["+prp+"] with data sharing agreements?";
     case "RenSchool::1":
        return "Are you sure you want to sync all student assessments within ["+prp+"]?";
      default:
        return "";
    }
  }

  const GetItalicsLabel=(action)=>{    
    switch(action){
      case "SyncNweaDistricts":
      case "AllDistrict":
        return "(Use when districts are missing from NWEA services)";
      case "SyncNweaDynamoSchools::3":
        return " (Use when assessment results are missing from a district with NWEA services. Assessments will be refreshed for the whole school year.)" ;
      case "SyncNweaDynamoStudents::2":
        return " (Use when assessment results are missing from a school with NWEA services. Assessments will be refreshed for the whole school year.)" ;
      case "School::2": 
        return " (Use when assessment results are missing from a school with NWEA services. Results will be refreshed based on the date selected plus 30 days after.)" ;
      case "School::1": 
      case "SyncNweaDynamoStudents::1":
        return " (Use when students are missing from schools with NWEA services)" ;
      case "District::1":
      case "SyncNweaDynamoSchools::1":
        return "(Use when students are missing from schools or districts with NWEA services)"
      case "District::2":
      case "SyncNweaDynamoSchools::2":
        return " (Use when schools are missing from a district with NWEA services)"
      case "RenSchool::1":
        return " (Use when assessment results are missing from a school with Renaissance services. Results will be refreshed based on the date selected plus 30 days after.)" ;            
      default:
        return "";
    }
  }

  const GetDialogTitle=(action)=>{    
    switch(action){
      case "SyncNweaDistricts":
        return "Nwea Districts Sync";
      case "SyncNweaDynamoStudents::1":
        return "Nwea Students Sync";
      case "AllDistrict":      
        return "District Sync";
      case "District::1":
      case "SyncNweaDynamoSchools::1":
      case "School::1":
        return "Sync Students";
      case "District::2":
      case "SyncNweaDynamoSchools::2":
        return "School Sync";
      case "School::2":
        return "Sync Student Assessments";
      case "SyncNweaDynamoSchools::3":
        return "Sync District Assessments";
      case "SyncNweaDynamoStudents::2":
        return "Sync School Assessments";
      default:
        return "";
    }
  }
  
  const getConfirmationMsg=(action)=>{
    switch(action){     
      case "District::1":
        return <div>Your request has been sent.<br /><div>*Note that assessment results for new students will automatically be processed from the beginning of the school year</div></div>;     
      default:
        return <div>Your request has been sent</div>;
    }
  }
  return (
    <DataContext.Provider
      value={{ userInfo,setUserInfo,GetBodyLabel,GetItalicsLabel,GetDialogTitle,ClearCacheData,getConfirmationMsg}}>
      {children}
    </DataContext.Provider>
  );
};
