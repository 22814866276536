import config from "../config/baseconfig";
import {formatTime} from "../utils/dateutils";

export const NWEAOrgAPI = `${config.NWEAAPI}v2.0/organizations`;
export const NWEADashboardAPI = `${config.NWEAAPI}v1.0/dashboard`;
export const NWEAResultAPI = `${config.NWEAAPI}v2.0/assessments/results`;
export const NWEASyncAPI =`${config.NWEAAPI}tasks/sync`;

export const RENAISSANCEOrgAPI =`${config.RENAISSANCEAPI}districts`;
export const AISAPI =`${config.AssessmentAPI}/organizations`;
export const AISAPIV1 = `${config.AssessmentAPIV1}`;
export const AISAPIV4 = `${config.AssessmentAPIV4}`;

export const getResults = (values, sch = false, dist = "") => {
  let modvalues = [];
  let i = 0;
  for (const element of values) {
    modvalues.push({
      rid: sch ? element.schoolBid : element.districtBid,
      id: i,
      name: element.name,
      district: dist,
      districtBid: element.districtBid,
    });
    i++;
  }
  return modvalues;
};

export const getDistrictsDataFromValuesV4 = (values, sch = false) => {
  let modvalues = [];
  let i = 0;
  for (const element of values) {
    modvalues.push({
      rid: sch ? element.organizations.vendorSchoolId : element.organizations.vendorDistrictId,
      id: i,
      name: element.name,
      schoolId: element.organizations.vendorSchoolId,
      districtId: element.organizations.vendorDistrictId,
    });
    i++;
  }  
  return modvalues;
};


 export const getStudentResults = (values) => {
   let modvalues = [];
   let i = 0;
   for (const element of values) {
     modvalues.push({
       studentBid: element.studentBid,
       rid: element.studentId,
       id: i,
       schoolname: element.schoolBid,
       name: element.lastName + "," + element.firstName,
     });
     i++;
   }
   return modvalues;
 };

  export const getStudentsDataFromValuesV4 = (values) => {
    let modvalues = [];
    let i = 0;    
    for (const element of values) {
      modvalues.push({
        studentId: element.studentId,
        vendorStudentId: element.vendorStudentId,
        id: i,
        schoolId: element.organizations.schoolId,
        districtId: element.organizations.vendorDistrictId,
        name: element.name,
      });
      i++;
    }
    return modvalues;
  };

  export const getAssessmentsDataFromValuesV4 = (values) => {
    let modvalues = [];
    let i = 0;
    for (const element of values) {
      let objIA = GetInstructionalAreasV4(element.domainAreas);
      modvalues.push({
        id:i,
        schoolId:element.organizations.schoolId,
        testName:element.testName,        
        rit: element.score,
        importDate: formatTime(element.importedOn,"MM/DD/YYYY"),
        testDate: formatTime(element.testDate,"MM/DD/YYYY"),
        IANames: objIA.IANames,
        IAScores: objIA.IAScores
      });
      i++;
    }
    return modvalues;
  };

  export const getStudentDetailRow=(values)=>{
      if(values.length===1){
        return {id:values[0].studentId}
      }
      return null;
  }
  const GetInstructionalAreas=function (arrIA) {
    let names = [];
    let scores = [];
    for (const element of arrIA) {
      names.push(element.instructionalAreaName);
      scores.push(element.score);
    }
    return { IANames: names, IAScores: scores };
  }
  const GetInstructionalAreasV4=function (arrIA) {
    let names = [];
    let scores = [];
    for (const element of arrIA) {
      names.push(element.domainName);
      scores.push(element.score);
    }
    return { IANames: names, IAScores: scores };
  }
  export const getAssessmentResults = (values) => {
    let modvalues = [];
    let i = 0;
    for (const element of values) {
      let objIA = GetInstructionalAreas(element.instructionalAreas);
      modvalues.push({
        id:i,
        schoolBid:element.schoolBid,
        testName:element.testName,        
        rit:element.rit,
        importDate: formatTime(element.importDate,"MM/DD/YYYY"),
        testDate: formatTime(element.testDate,"MM/DD/YYYY"),
        IANames:objIA.IANames,
        IAScores:objIA.IAScores     
      });
      i++;
    }
    return modvalues;
  };

  export const getAssessmentResultsV4 = (values) => {
    let modvalues = [];
    let i = 0;
    console.log(values);
    for (const element of values) {
      let objIA = GetInstructionalAreasV4(element.instructionalAreas);
      modvalues.push({
        id:i,
        schoolBid:element.schoolBid,
        testName:element.testName,        
        rit:element.rit,
        importDate: formatTime(element.importDate,"MM/DD/YYYY"),
        testDate: formatTime(element.testDate,"MM/DD/YYYY"),
        IANames:objIA.IANames,
        IAScores:objIA.IAScores     
      });
      i++;
    }
    return modvalues;
  };


  export const setRPIDResults = (values) => {
    let modvalues = [];
    let i = 0;
    for (const element of values) {  
      modvalues.push({
        rpid: element.externalId,
        clientid: element.renaissanceClientId,
        districtName: element.name,
        id:i,
        entityType:element.entityType,
        createdon: formatTime(element.modifiedOn,"MM/DD/YYYY HH:MM"),
      });
      i++;
    }
    return modvalues;
  };
  export const setRPIDResultsV4 = (values) => {
    let modvalues = [];
    let i = 0;
    for (const element of values) { 
      modvalues.push({
        rpid: element.organizations.districtId,
        clientid: element.organizations.vendorDistrictId,
        districtName: element.name,
        id:i,
        entityType:element.vendorType,
        createdon: formatTime(element.importedOn,"MM/DD/YYYY HH:MM"),
      });
      i++;
    }
    return modvalues;
  };
  export const setRenStudentResults = (values,startIndex=0) => {
    let modvalues = [];  
    for (let i = startIndex; i < values.length; i++) {
      let obj = values[i];
      modvalues.push({
        id:i,
        rid: obj.studentId,
        testdate:formatTime(obj.testDate,"MM/DD/YYYY HH:MM"), 
        importdate:formatTime(obj.modifiedOn,"MM/DD/YYYY HH:MM"),
        test:obj.testName, 
        score:obj.score, 
        uscore:obj.score,
        HasMoreResults: obj.hasMoreRecords   
      });
    }
    return modvalues;
  };
  export const setRenStudentResultsv4 = (values,startIndex=0) => {
    let modvalues = [];  
    for (let i = startIndex; i < values.length; i++) {
      let obj = values[i];   
      modvalues.push({
        id:i,
        studentId: obj.studentId,
        vendorStudentId: obj.vendorStudentId,
        studentAssessmentId: obj.assessmentId,
        testdate:formatTime(obj.testDate,"MM/DD/YYYY HH:MM"), 
        importdate:formatTime(obj.importedOn,"MM/DD/YYYY HH:MM"),
        test:obj.testName, 
        score:obj.score, 
        uscore:obj.score,
        HasMoreResults: obj.hasMoreRecords   
      });
    }
    return modvalues;
  };
export const getRenStudentDetailRow=(values)=>{
  if(values.length>0 && values[0].hasMoreRecords){    
   return {id:values[0].studentId}
  }
  return null;
}
  export const FetchJson = async (url,app='N') => {
    const controller = new AbortController();    
    const id = setTimeout(() => controller.abort(), 8000);  
    const token = app==='N'?config.NWEAToken:config.RENAISSANCEAPIToken;
    return fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      signal:controller.signal
    })
      .then((res) => res.json())
      .then((res) => {        
        clearTimeout(id);
        if (!res.values) {          
          return { size: 0, page: 1, values: [] ,timeout:false};
        }
        return res;
      },
      (error) =>{
        console.log("API call failed :" + error + ". Url: " + url);
        return { size: 0, page: 1, values: [],timeout:true };
      }
      );
      
  }; 
  export const FetchJsonDynamo = async (url) => {
    const controller = new AbortController();    
    const id = setTimeout(() => controller.abort(), 100000);  
    const token = config.AssessmentAPIToken;
    const app=config.AssessmentAPISrc;
    return fetch(url, {
      headers: { Authorization: `Bearer ${token}`,Application:`${app}` },
      signal:controller.signal
    })
      .then((res) =>{
       if(res.status===404){
        return JSON.stringify({ size: 0, paginationtoken: undefined, values: [] ,timeout:true});
       }
       return res.json();
      }
       )
      .then((res) => {        
        let paginationtoken =undefined;      
        clearTimeout(id);
        if (!res.results) {          
          return { size: 0, paginationtoken: paginationtoken, values: [] ,timeout:false};
        }       
        if(res.paginationToken)
         {
           paginationtoken=res.paginationToken;
         }
        return { size:res.results.length,values:res.results,paginationtoken:paginationtoken};
      },
      (error) =>{
        console.log("API call failed :"+error);
        return { size: 0, paginationtoken: undefined, values: [] ,timeout:true};
      }
      );
      
  }; 
  export const FetchJsonPostgres = async (url) => {
    const controller = new AbortController();    
    const id = setTimeout(() => controller.abort(), 8000);  
    const token = config.AssessmentAPIToken;
    const app=config.AssessmentAPISrc;
    return fetch(url, {
      headers: { Authorization: `Bearer ${token}`,Application:`${app}` },
      signal:controller.signal
    })
      .then((res) => res.json())
      .then((res) => {
        clearTimeout(id);
        if (!res.values) {          
          return { size: 0, page: 1, values: [] ,timeout:false};
        }        
        return res;
      },
      (error) =>{
        console.log("API call failed :"+error);
        return { size: 0, page: 1, values: [] ,timeout:true};
      }
      );
      
  }; 
  export const PostJson = async (url,req,app='N') => {
    const token = app==='N'?config.NWEAToken:config.RENAISSANCEAPIToken;
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify(req.params)
    }).then((res) => res.json())
    .catch(error => console.log(error) );
  };

  export const PostJsonDynamo = async (url, req) => {
    const token = config.AssessmentAPIToken;
    const app = config.AssessmentAPISrc;
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Application:`${app}`
      },
      body:JSON.stringify(req.params)
    }).then((res) => res.json())
    .catch(error => console.log(error) );
  };

  export const PostJsonPostgres = async (url,req) => {
    const token = config.AssessmentAPIToken;
    const app=config.AssessmentAPISrc;
    return fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Application:`${app}`
      },
      body:JSON.stringify(req)
    }).then((res) => res.json())
    .catch(error => console.log(error) );
  };

  export const CreateRequestFromPrevious = (previousRequest, newPaginationToken) => {
    return{
      id: previousRequest.id,
      paginationToken: newPaginationToken,
      params: previousRequest.params
    };
  };

   /* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
  export default {
    FetchJson,
    getResults,
    getDistrictsDataFromValuesV4,
    getStudentResults,
    getStudentsDataFromValuesV4,
    PostJson,
    PostJsonDynamo,
    setRenStudentResults,
    setRPIDResults,    
    getRenStudentDetailRow,
    PostJsonPostgres,
    NWEAOrgAPI,
    NWEADashboardAPI,
    NWEAResultAPI,
    NWEASyncAPI,
    RENAISSANCEOrgAPI,
    CreateRequestFromPrevious
  }; 

