// Original DistrictPage.jsx with okta

import React, { useState, useEffect } from "react";
import BasePage from "./CommonPages/BasePage";
import SearchBar from "../Common/SearchBar";
import ResultsTable from "../Common/ResultsTable";
import NweaDynamoResultsTable from "../Common/NweaDynamoResultsTable";
import RenResultsTable from "../Common/RenResultsTable";
import RenDynamoResultsTable from "../Common/RenDynamoResultsTable";
import { nweaservice } from "../../Services/nweaservice";
import { nweaDynamoService } from "../../Services/nweaDynamoService";
import { renService } from "../../Services/renService";
import { dynamoRenService } from "../../Services/DynamoRenService";
import { useOktaAuth } from "@okta/okta-react";
import config from "../../config/baseconfig";
import { useHistory } from "react-router-dom";
import {
  NweaDistrictPageColumns,
  NweaDistrictDetailsColumns,
  DynamoNweaDistrictPageColumns,
  DynamoNweaDistrictDetailsColumns,
  RenDistrictPageColumns,
} from "../Common/columns";
import Button from "@material-ui/core/Button";
import { ConfirmDialog } from "../Common/Dialog/ConfirmDialog";

const ResultTbl = React.memo(function ResultTbl(props) {
  const { query, handleDialogOpen, dialogStatus } = props;
  return (
    <ResultsTable
      columns={NweaDistrictPageColumns}
      detailColumns={NweaDistrictDetailsColumns}
      showDetailHeaders={false}
      syncKey="District"
      syncDetailKey="School"
      query={query}
      tooltiptext="Show Schools"
      GetService={nweaservice.searchDistrictsAndSchools}
      DetailService={nweaservice.searchSchools}
      handleDialogOpen={handleDialogOpen}
      dialogStatus={dialogStatus}
    />
  );
});

const NweaDynamoResultTbl = React.memo(function NweaDynamoResultTbl(props) {
  const { query, handleDialogOpen, dialogStatus } = props;

  return (
    <NweaDynamoResultsTable
      columns={DynamoNweaDistrictPageColumns}
      detailColumns={DynamoNweaDistrictDetailsColumns}
      showDetailHeaders={false}
      syncKey="SyncNweaDynamoSchools"
      syncDetailKey="SyncNweaDynamoStudents"
      query={query}
      tooltiptext="Show Schools"
      GetService={nweaDynamoService.searchDistrictsAndSchools}
      DetailService={nweaDynamoService.searchSchools}
      handleDialogOpen={handleDialogOpen}
      dialogStatus={dialogStatus}
    />
  );
});

function DistrictPage() {
  const { authState } = useOktaAuth();
  const hasLoggedIn = authState && authState.isAuthenticated;
  const [query, setQuery] = useState({
    searchName: "",
    startdate: null,
    enddate: null,
    valid: true,
    searched: false,
    expView: false,
  });
  const doTheSearch = (value) => {
    setQuery(value);
  };  
  const nweahangfireurl = hasLoggedIn ? `${config.NWEAAPI.replace("api/", "")}hangfire?token=${authState.accessToken.accessToken}`:"";
  const [rowAction, setRowAction] = useState("");
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({ rid: "", name: "" });
  const errorMessage = "Please enter minimum 3 characters for search.";
  const handleDialogOpen = (e, rowData, action) => {
    setOpen(true);
    setRowAction(action);
    setRowData(rowData);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDisabledClick = (e, rowData, action) => {
    console.log('Sync not enabled for Renaissance DynamoDB');
  };
  const handleDialogClose = (e) => {
    setOpen(false);
    e.preventDefault();
    e.stopPropagation();
  };
  const validator = (value) => {
    return value?.length === 0 || value?.trim().length >= 3;
  };
  const history = useHistory();
  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.id
    ) {
      setQuery({
        ...query,
        searchName: history.location.state.id,
        expView: true,
      });
      history.replace({ pathname: "/", state: null });
    }
  }, [query, history]);

  return (
    <BasePage>
      <div label="NWEA (IL Math, MyPathK12)">
        <div className="aboveBlock">
          <SearchBar
            onSearchClick={doTheSearch}
            validator={validator}
            placeholderText="Search by District name or District/School BID"
            validationErrorMessage={errorMessage}
          />
          <div className="masterSyncButton">
            <Button
              style={{ color: "black", background: "#d8e3e6" }}
              onClick={(e) => handleDialogOpen(e, "", "AllDistrict")}
              component="span"
            >
              Sync all Districts
            </Button>
          </div>
          <div className="hangfireDashboardbtn">
            <Button
              style={{ color: "blue", background: "#d8e3e6" }}
              onClick={() =>                
                nweahangfireurl.length > 0 &&
                window.open(nweahangfireurl, "_blank")
              }
              component="span"
              aria-describedby="info"
              title="A Dashboard to see the status NWEA Sync Jobs"
            >
              Hangfire Queue
            </Button>
          </div>
        </div>     
        <ConfirmDialog
          open={open}
          handleClose={handleDialogClose}
          action={rowAction}
          id={rowData.rid}
          name={rowData.name}
        />
        <ResultTbl
          query={query}
          handleDialogOpen={handleDialogOpen}
          dialogStatus={open}
        ></ResultTbl>
      </div>
      <div label="NWEA (No Products)">
        <div className="aboveBlock">
          <SearchBar
            onSearchClick={doTheSearch}
            validator={validator}
            placeholderText="Search by District/School BID"
            validationErrorMessage={errorMessage}
          />
          <div className="masterSyncButton">
            <Button
              style={{ color: "black", background: "#d8e3e6" }}
              onClick={(e) => handleDialogOpen(e, "", "SyncNweaDistricts")}
              component="span"
            >
              Refresh District List from NWEA
            </Button>
          </div>
        </div>
        <ConfirmDialog
          open={open}
          handleClose={handleDialogClose}
          action={rowAction}
          id={rowData.rid}
          districtid={rowData.districtId}
          name={rowData.name}
        />
        <NweaDynamoResultTbl
          query={query}
          handleDialogOpen={handleDialogOpen}
          dialogStatus={open}
        ></NweaDynamoResultTbl>
      </div>
      <div label="Renaissance (MyPathK12)">
        <div>
          <div className="aboveBlock">
            <SearchBar
              onSearchClick={doTheSearch}
              validator={validator}
              placeholderText="Search by RPID"
              validationErrorMessage={errorMessage}
            />
          </div>
          <ConfirmDialog
            open={open}
            handleClose={handleDialogClose}
            action={rowAction}
            id={rowData.rpid??rowData.clientid}
            name={rowData.rpid??rowData.clientid}
          />
          <RenResultsTable
            columns={RenDistrictPageColumns}
            hideDetail={true}
            GetService={renService.searchDistrictsAndSchools}
            query={query}
            syncKey="RenSchool"
            handleDialogOpen={handleDialogOpen}
            dialogStatus={open}
          />
        </div>
      </div>
      { <div label="Renaissance (No Products)">
        <div>
          <div className="aboveBlock">
            <SearchBar
              onSearchClick={doTheSearch}
              validator={validator}
              placeholderText="Search by RPID"
              validationErrorMessage={errorMessage}
            />
          </div>
          <ConfirmDialog
            open={open}
            handleClose={handleDialogClose}
            action={rowAction}
            id={rowData.rpid??rowData.clientid}
            name={rowData.rpid??rowData.clientid}
          />
          <RenDynamoResultsTable
            columns={RenDistrictPageColumns}
            hideDetail={true}
            GetService={dynamoRenService.searchDistrictsAndSchools}
            query={query}
            syncKey="DisabledRenSchool"
            handleDialogOpen={handleDisabledClick}
            dialogStatus={open}
          />
        </div>
      </div> }
    </BasePage>
  );
}

export default DistrictPage;