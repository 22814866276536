import {useContext} from 'react';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignInWidget from './OktaSignInWidget';
import {DataContext} from "../Common/DataContext";
function Login() {
  const { oktaAuth, authState } = useOktaAuth();
  const context=useContext(DataContext);
  const onSuccess = function(res) {
    if (res.status === 'SUCCESS') {
      return oktaAuth.signInWithRedirect({
        sessionToken: res.session.token
      });
    }
  }

  const onError = function(err) {
    context.setUserInfo(null);
    console.log('error logging in', err);
  }

  return authState && authState.isAuthenticated ?
    <Redirect to={{ pathname: '/' }}/> :
    <OktaSignInWidget      
      onSuccess={onSuccess}
      onError={onError}/>;
}

export default Login;