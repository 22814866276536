import {PostJson,NWEASyncAPI} from './Helper';
export const nweasyncservice = { 
  syncAllDistricts: async (req) => {
    const url = `${NWEASyncAPI}/districts?apptype=${req.apptype}&UserID=${req.userinfo.email}`;  
    return PostJson(url, req);
  },
  syncDistrict: async (req) => {
    const url = `${NWEASyncAPI}/districts/${req.params.id}?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },

  syncSchoolsOfDistrict: async (req) => {
    const url = `${NWEASyncAPI}/districts/${req.params.id}/Schools?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },

  syncSchool: async (req) => {
    const url = `${NWEASyncAPI}/schools/${req.params.id}?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },
  syncStudentsOfDistrict: async (req) => {
    const url = `${NWEASyncAPI}/districts/${req.params.id}/Students?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },
  syncStudentsOfSchool: async (req) => {
    const url = `${NWEASyncAPI}/schools/${req.params.id}/Students?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },
  syncStudent: async (req) => {
    const url = `${NWEASyncAPI}/students/${req.params.id}?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },
  syncAssessmentsBySchool: async (req) => {
    const url = `${NWEASyncAPI}/schools/${req.params.id}/Assessments?apptype=${req.apptype}&testDate=${req.params.startDate}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  },
  syncAssessmentsByStudent: async (req) => {
    const url = `${NWEASyncAPI}/students/${req.params.id}/Assessments?apptype=${req.apptype}&UserID=${req.userinfo.email}`;
    return PostJson(url, req);
  }  
};