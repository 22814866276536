function CollapseOtherRows(tblRef,clickedRowIndex){
  if (tblRef.current && 
    tblRef.current.tableContainerDiv.current &&
    tblRef.current.tableContainerDiv.current.children[0] &&
    tblRef.current.tableContainerDiv.current.children[0].childNodes[0] &&
    tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1] &&
    tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1].children
    ) {
      let rows =
         tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1].children;            
       for (let i = 0; i < rows.length; i++) {
        if(rows[i].getAttribute('index')!==clickedRowIndex){
         let expanded = rows[i].getAttribute("expanded");
         if (expanded==="true") {             
             rows[i].removeAttribute('expanded');
             rows[i].click()
            //  let btn = rows[i].children[0].childNodes[0].childNodes[0];          
            //  if("BUTTON"===btn.tagName){
            //   btn.click();}           
         }}
       }
    }
}

export function handleExpandClick(event,tblRef){
  if(event.path){
  let btn=event.path[0]; 
  let label=btn.getAttribute('aria-label');
  if("Detail panel visibility toggle" === label){
    let clickedRow = event.path[3];
    let exp=clickedRow.getAttribute('expanded');
    if("true" !==exp){    
    clickedRow.setAttribute('expanded', 'true');
    let clickedRowIndex=clickedRow.getAttribute('index');
    CollapseOtherRows(tblRef,clickedRowIndex)
   }
   else{
    clickedRow.removeAttribute('expanded');
   }
  }
  }
 

}
export function handleRowClick(event,tblRef) {
     if (!event.nativeEvent.path) {
       return;
     }   
 
    let clickedRow = event.nativeEvent.path[5];
    if("TD"===clickedRow.nodeName){
      clickedRow = clickedRow.parentNode;
    }
    clickedRow.setAttribute('expanded', 'true');
   let clickedRowIndex=clickedRow.getAttribute('index');
   CollapseOtherRows(tblRef,clickedRowIndex)
  };

  export function expandRow(tblRef, btnIndex) {
 
    if (tblRef.current && 
      tblRef.current.tableContainerDiv.current &&
      tblRef.current.tableContainerDiv.current.children[0] &&
      tblRef.current.tableContainerDiv.current.children[0].childNodes[0] &&
      tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1] &&
      tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1].children
      ) {
      let rows =
        tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1].children;  
        let i=0;    
      //for (let i = 0; i < rows.length; i++) {      
       // if (rows[i].innerText.indexOf(btnIndex) > 0) {         
          let expanded = rows[i].getAttribute("expanded");
          if (expanded==="true") { return;}
          let firstrow = rows[i];
          if (
            firstrow &&
            firstrow.childNodes[0] &&
            firstrow.childNodes[0].childNodes[0] &&
            firstrow.childNodes[0].childNodes[0].childNodes[0]
          ) {
           // let btn = firstrow.childNodes[0].childNodes[0].childNodes[0];
            firstrow.setAttribute("expanded", "true");
            firstrow.click();   
            // if("BUTTON"===btn.tagName){
            // btn.click();}
          }
      //  }
     // }
    }
  }
  export function collapseRow(tblRef) {

    if (tblRef.current && 
      tblRef.current.tableContainerDiv.current &&
      tblRef.current.tableContainerDiv.current.children[0] &&
      tblRef.current.tableContainerDiv.current.children[0].childNodes[0] &&
      tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1] &&
      tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1].children
      ) {
      let rows =
        tblRef.current.tableContainerDiv.current.children[0].childNodes[0].childNodes[1].children; 
      for (let i = 0; i < rows.length; i++) {
        if("No data found"===rows[i].innerText){rows[i].removeAttribute('expanded');return;}
        let expanded = rows[i].getAttribute("expanded");
        if ("true"===expanded) {          
          let firstrow = rows[i];          
          if (
            firstrow &&
            firstrow.childNodes[0] &&
            firstrow.childNodes[0].childNodes[0] &&
            firstrow.childNodes[0].childNodes[0].childNodes[0]
          ) {
          //  let btn = firstrow.childNodes[0].childNodes[0].childNodes[0];
            firstrow.removeAttribute('expanded');           
            firstrow.click();        
            // if("BUTTON"===btn.tagName){
            //   btn.click();}
          }
        }
      }
    }
  }
  export function handleRowCollapse(event){ 
    if (!event.nativeEvent.path) {
      return;
    }       
   let clickedRow = event.nativeEvent.path[5]; 
   if("TD"===clickedRow.nodeName){
    clickedRow = clickedRow.parentNode;
   }
   clickedRow.removeAttribute('expanded');
  }
  export const DataNotFoundMessage=(props)=>{
  var className="tableNotFoundHdr";
  var errMessage="No match found";
  if(props.timeoutError){
    className = className + " warn";
    errMessage="An error occurred, please try again";
  }
  return <div className={className}>{errMessage}</div>;
  }