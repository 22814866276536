import * as React from "react";
import Select from "react-select";
import moment from "moment";
import { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";

type onTimeFrameChange = (startDate: string, endDate: string, selectedValue: string) => void;

export interface TimeFrameSelectorProps {
  onResetTime: string;
  onNoResetTime: Function;
  onTimeFrameChange: onTimeFrameChange;
  setDefaultValue: string;
}

interface TimeFrameProps {
  startDate: any;
  endDate: any;
  selectedValue: string;
  selectedDate: any;
  calenderVisible: boolean;
  startCustomDate: any;
  endCustomDate: any;
  minCustomDate: any;
}

function DateValue(value: string): number{
  if (customSelected){
    customSelected = false;
  }
  if (value === "Today"){
    return 0;
  } else if (value==="Last 24 Hours"){
    return 1;
  } else if (value==="Last 7 Days"){
    return 7;
  } else if (value==="Last 28 Days"){
    return 28;
  }else if (value==="Last 12 Months"){
    return (12*30);
  }else if (value==="Custom"){
    customSelected= true;
    return 7;
  }
return 1;
}

var defaultValue = "Last 7 Days";
var customSelected= false;
var customDisplayLabel = " ";

class TimeFrameSelector extends Component<TimeFrameSelectorProps, TimeFrameProps> {
  constructor(props: TimeFrameSelectorProps) {
    super(props);
    const { newDate, toDay } = this.getDates(7);
    defaultValue = this.props.setDefaultValue;

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      endCustomDate: moment(), // set your initial end date here
      startCustomDate: moment().subtract(7, "days").startOf("day"), // set your initial start date here
      minCustomDate: moment().subtract(364, "days"), // min date it can choose is 365 days
      selectedValue: defaultValue,
      selectedDate: newDate + " - " + toDay,
      calenderVisible: false
    };
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidUpdate() {
    if (this.props.onResetTime === defaultValue) {
      if (this.state.selectedValue !== this.props.onResetTime) {
        this.onResetTime();
      }
    }
  }

  getDates(daysToSubstract: number) {
    const date: Date = new Date();

    const endDate: string = date.toISOString();
    const toDay = this.getReadableDate(date);

    date.setUTCHours(0, 0, 0, 0);
    let startDate: string | number = date.setDate(date.getDate() - daysToSubstract);
    startDate = new Date(startDate).toISOString();
    const newDate = this.getReadableDate(date);

    return {
      startDate,
      newDate,
      endDate,
      toDay
    };
  }

  getReadableDate(date: Date) {
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const formattedDay = day < 10 ? `0${day}` : day;
    const year = date.getFullYear();

    return `${month} ${formattedDay}, ${year}`;
  }

  handleChange(value: any) {
    if (this.props.onResetTime === defaultValue) {
      this.props.onNoResetTime();
    }

    const { startDate, newDate, endDate, toDay } = this.getDates(DateValue(value));

    if (value === 'Custom') {
      this.setState({ calenderVisible: true });
    } else if (value === 'Today') {
      this.setState({ selectedValue: value, selectedDate: toDay });
    } else {
      this.setState({ selectedValue: value, selectedDate: newDate + " - " + toDay });
    }

    this.props.onTimeFrameChange(startDate, endDate, value);
  }

  onResetTime() {
    const { newDate, toDay } = this.getDates(7);
    this.setState({ selectedValue: defaultValue, selectedDate: newDate + " - " + toDay });
  }

  handleCallback(start: any, end: any) {
    if (this.props.onResetTime === defaultValue) {
      this.props.onNoResetTime();
    }

    this.setState({ calenderVisible: false });
    const toDay = start.format("MMM DD, YYYY");
    const newDate = end.format("MMM DD, YYYY");
    this.setState({ selectedValue: "Custom", selectedDate: toDay + " - " + newDate });
    const startDate = start.format("YYYY-MM-DDT00:00Z");
    const endDate = end.utc().format("YYYY-MM-DDTHH:mmZ");
    this.props.onTimeFrameChange(startDate, endDate, this.state.selectedValue);
  }

  render() {
    if (customSelected){
      customDisplayLabel = (":   " + this.state.selectedDate);
    } else {
      customDisplayLabel = " ";
    }
    const options = [
      { value: "Date", label: this.state.selectedValue.toUpperCase(), disabled: "yes" },
      { value: "Date", label: this.state.selectedDate, disabled: "yes", line: "yes" },
      { value: "Last 24 Hours", label: "Last 24 Hours" },
      { value: "Last 7 Days", label: "Last 7 Days" },
      { value: "Last 12 Months", label: "Last 12 Months" },
      { value: "Custom", label: ("Custom" + customDisplayLabel)}
    ];

    if (this.state.calenderVisible) {
      return (
        <DateRangePicker
          onCallback={this.handleCallback}
          initialSettings={{
            startDate: this.state.startCustomDate,
            endDate: this.state.endCustomDate,
            maxDate: this.state.endCustomDate,
            minDate: this.state.minCustomDate
          }}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
      );
    }

    const customStyles = {
      option: (provided: any, state: { isSelected: any }) => ({
        ...provided,
        backgroundColor: state.isSelected ? "rgb(38, 151, 216)" : "white"
      })
    };

    const selectedValues = options.filter(({ value }) => value === this.state.selectedValue);
    const defaultValues = options.filter(({ value }) => value === defaultValue);
    const placeholder = selectedValues.length === 0 ? "Select time frame" : selectedValues[0].label;
    return (
      <div className="aboveTable">
        <div className="ur-selector-timeframe">
          <Select
            aria-label="timeframe-selector"
            aria-labelledby="timeframe-selector"
            className="ur-react-select"
            styles={customStyles}
            defaultValue={defaultValues[0].label}
            placeholder={placeholder}
            options={options}
            onChange={(event: any) => this.handleChange(event.value)}
            isOptionDisabled={(option) => option.disabled === "yes"}
            isSearchable={false}
            value={options.find((obj) => obj.value === this.state.selectedValue)}
          />
        </div>
      </div>
    );
  }
}

export default TimeFrameSelector;
