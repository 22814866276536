import {FetchJson,getResults,getAssessmentResults,NWEADashboardAPI,NWEAResultAPI} from './Helper';
import {nweaOrgService} from "./nweaOrgService";
import { validateGuid } from "../utils/validationutils";
export const nweaservice = {
  searchDistrictsAndSchools: async (req) => {
     let searchbyid=req.id.length===0||validateGuid(req.id);    
    return nweaOrgService.searchDistrictsAndSchools({...req,searchByGuid:searchbyid});
  },   
  searchSchools: async (req) => {    
    let searchName=req.params.searchName;
    let searchByGuid=searchName.length > 0 && validateGuid(searchName);
    let searchedSchool = searchByGuid && req.id !== searchName && req.params.searched;
    
    if (searchedSchool) {
      return nweaOrgService.getSchool({...req,id:searchName,isSchool:true,searchByGuid:searchByGuid}).then((resp) => {        
        return { ...resp, values: getResults(resp.values, true,req.dtName) };
      });
    } else {
      return nweaOrgService.getSchoolsByDistrict(req);
    }
  },

  getStudents: async (req) => {     
    if (req.params.searched) {
      return nweaOrgService.getStudentsByOrgByStudentId(req);
    } else {
      return nweaOrgService.getStudentsByOrg(req);
    }
  },

  getAssessmentlog: async (days) => {
    const url = `${NWEADashboardAPI}/assessmentlog?days=$(days)`;
    return FetchJson(url);
  },
  getResultsByOrgByStudent: async (req) => {  
    let org = req.isSchool ? "schools" : "districts";
    let studentId=req.id;    
    const url = `${NWEAResultAPI}/${org}/${req.params.org.id}/students/${studentId}?page=${req.page}&pageSize=${req.pageSize}&TestDateStart=${req.params.StartDate}&TestDateEnd=${req.params.EndDate}`;   
    return FetchJson(url).then((resp) => {
      return { ...resp, values: getAssessmentResults(resp.values) };
    });
  },
};
