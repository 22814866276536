import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";

export default function SearchBar(props) {
  const [prevSearch,setPrevSearch]=useState("");
  const [values, setValues] = useState({
    searchName: "", 
    valid:true,
    searched:false
  });

  const setSearchPage = (value) => {   
    if (props.validator(value)) {
      setValues({ ...values,searchName: value, valid:true,searched:true });     
    } else {      
      setValues({ ...values,searchName: value, valid:false });
    }};

  const handleChange = (event) => {
    setSearchPage(event.target.value);
    if(event.target.value.length===0){
      props.onSearchClick({ searchName: "",valid:true,searched:false,reload:true });
    }
  };

  const handleClickClose = () => {
    setValues({ searchName: "",valid:true,searched:true });  
    setPrevSearch("");
    props.onSearchClick({ searchName: "",valid:true,searched:false,reload:true });
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };
  const handleEnter = (event) => {
    event.preventDefault();    
    if(event.keyCode===13 && values.valid && ""!==event.target.value && prevSearch!==values.searchName){ 
      setPrevSearch(values.searchName) ;    
      props.onSearchClick(values);
    }
    if(event.keyCode!==13 &&""===event.target.value) {
      setValues({ ...values, searchName: "",valid:true });
    }
  };
  const handleSearch = (event) => {
    if(values.valid && prevSearch!==values.searchName){
      setPrevSearch(values.searchName) ;
    props.onSearchClick(values);
  }
  };
  return (
    <div className="aboveTable">
      <div className={values.valid ? "searchBarDiv":"searchBarDiv invalid"}>
        <Input
          className={values.valid ? "searchBar":"searchBar invalid"} id="input-Search" type="text" value={values.searchName}
          onChange={handleChange} onKeyUp={handleEnter}         
          placeholder={props.placeholderText}
          endAdornment={
            <InputAdornment position="end">
              <IconButton style={{paddingRight:'1px',paddingLight:'1px'}} onClick={handleClickClose} onMouseDown={preventDefault}> {values.searchName?.length > 0 ? <Close /> : ""}</IconButton>
              <IconButton style={{paddingRight:'1px',paddingLight:'1px'}} onMouseDown={preventDefault} onClick={handleSearch}>  <SearchIcon />  </IconButton>
            </InputAdornment>
          }
        />
        <p>{props.validationErrorMessage}</p>
      </div>
    </div>
  );
}
