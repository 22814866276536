import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign:'center',
    padding:'10px'

  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    margin:'auto',
    width: 140,
  },
}));

export default function DatePicker(props) {
  const classes = useStyles();
const {handleDateChange,defaultValue}=props;
  return (    
    <div className={classes.container} noValidate>
      <TextField
        id="date"        
        type="date"
        defaultValue={defaultValue}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleDateChange}
      />
    </div>
  );
}
