import BasePage from "./CommonPages/BasePage";
import { useState, useEffect } from "react";
import { Link,useHistory } from 'react-router-dom';
import { nweaservice } from "../../Services/nweaservice";
import SearchBar from "../Common/SearchBar";
import ResultsTable from "../Common/ResultsTable";
import { NweaStudentPageColumns,NweaStudentDetailsColumns } from "../Common/columns";
import {formatTime,last12Months} from '../../utils/dateutils';
import TimeFrameSelector from "../Common/CalendarPicker";

export default function StudentPage(props) {
  const [org, setOrg] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSchool, setisSchool] = useState(false);
  const errorMessage="Please enter at least three characters for a valid search.";
  const [query, setQuery] = useState({
    searchName: "",
    StartDate:formatTime(last12Months(),"MM/DD/YYYY"),
    EndDate:formatTime(new Date(),"MM/DD/YYYY"),  
    valid:true,
    searched:false
  });
  const history = useHistory();
  
  useEffect(() => {
    if(props.location.state){
    setOrg(props.location.state.data);
    setisSchool(props.location.state.data.isDetail);
    setIsLoaded(true);
    }else{
      history.push('/');
    }

  }, [props.location.state,history]);

  const handleSearch = (value) => {
    setQuery({...query,searchName:value.searchName,valid:value.valid,searched:value.searched});
  };
  const validator = (value) => {
    return value?.length===0 || value?.trim().length>=3;
  };
 const handleDateSelection=(startDate, endDate,selected)=>{       
     setQuery({...query,StartDate:formatTime(startDate,"MM/DD/YYYY"),EndDate:formatTime(endDate,"MM/DD/YYYY")});
 }
 const BreadCrumb=()=>{
  
  const handleRowClick=(event)=>{    
    let bid=isSchool?org.districtBid:org.id;    
    history.push({pathname:"/",state:{id:bid}});
    event.preventDefault();
  }
  let district=<a href="/" target="_blank" onClick={handleRowClick}>{isSchool?org.district:org.name}</a>;
  let label=isSchool?<>&nbsp;&gt;&nbsp;{org.name}</>:<></>;
  return (<p><Link to="/">District Dashboard</Link>&nbsp;&gt;&nbsp;{district}{label}</p>)

}
  return (
    <BasePage>    
      <div label="NWEA (IL Math, MyPathK12)">
        {!isLoaded ? (
          <div className="ct-chart">Loading...</div>
        ) : (
          <div>
          <BreadCrumb/>
        
        <div className="banner-text">
          <p>
            NOTE: FOR INTERNAL USE ONLY. DO NOT SHARE INFORMATION OUTSIDE WNE
          </p>
            
        </div>
         <SearchBar
          onSearchClick={handleSearch}
          validator={validator}
          placeholderText="Search School/District by Student ID or Name"
          validationErrorMessage={errorMessage}
        />
        <TimeFrameSelector 
        onTimeFrameChange={handleDateSelection}
        setDefaultValue="Last 12 Months"
        />
        <ResultsTable
          columns={NweaStudentPageColumns}
          detailColumns={NweaStudentDetailsColumns}
          showDetailHeaders={true}
          isSchool={isSchool}
          isLoading={!isLoaded}
          syncKey="Student"
          tooltiptext="Show Assessments"
          query={{ ...query,org:org}}
          GetService={nweaservice.getStudents}
          DetailService={nweaservice.getResultsByOrgByStudent}      
        /> 
        </div>
        )}
      </div>
      <div label="NWEA (No Products)">Return to home to switch data sources</div>
      <div label="Renaissance (MyPathK12)">
        Not available for this School/District Code
      </div>
      <div label="Renaissance (No Products)">
        Not available for this School/District Code
      </div>
    </BasePage>
  );
}
