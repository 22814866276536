import MaterialTable from "@material-table/core";
import { useState,  createRef, useEffect } from "react";
import { tableIcons } from "./TableProperties";
import { getRowActions } from "./DialogProperties";
import ExpandLessTwoToneIcon from "@material-ui/icons/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import DynamoDetailTable from "./DynamoDetailTable";
import { handleRowClick, expandRow,handleRowCollapse,collapseRow,handleExpandClick,DataNotFoundMessage } from "../../utils/TableUtils";

const RenDynamoResultsTable = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);  
  const [paging,setPaging]=useState(false);
  const [timeoutError,setTimoutError]=useState(false);
  const tblRef = createRef();   
  let [pageDataCache,setPageDataCache] =useState([]) ;
  const [expandDetail] = useState(false);
  const [rowId]  = useState("");
  const [tblRowCount,setTblRowCount]=useState(10);
  const [pageToken]=useState(undefined);

  const {query,GetService}=props;
  const {searched,searchName,timeFrameChanged}=query;
  const handlePageChange=(nextPage,rowCount)=> {
    setTblRowCount(rowCount);
    if(pageDataCache.length && pageDataCache[0].pageSize !== rowCount)
    {
      setPageDataCache([]);
      query.pageSize = rowCount;
    }
  };

  const setData=(req,tableQuery) => new Promise((resolve, reject) => {

    let currentPageData = pageDataCache.find((cache) => { return cache.page === tableQuery.page; });

        if(currentPageData !== undefined && !req.params.searched)
        {   
          setPaging(true);        
          resolve({
            data: currentPageData.data,
            page: currentPageData.page,
            totalCount: currentPageData.paginationToken !== undefined ? ((tableQuery.page + 1)*tableQuery.pageSize) + 1 : ((tableQuery.page + 1)*tableQuery.pageSize)
        });
        }
        else
        {
          req.paginationToken = (pageDataCache.length && !req.params.searched) ? pageDataCache[pageDataCache.length - 1].paginationToken : undefined;
          
          if(req.params.pageSize !== tblRowCount)
          {
            req.params.pageSize = tblRowCount;
          }

          GetService(req)
            .then((result) => {
              setIsLoaded(true);         
              setPaging(result.paginationtoken !== undefined) ;
              setTimoutError(result.timeout); 
              if(!req.params.searched) {
                pageDataCache.push({page: tableQuery.page, data: result.values, paginationToken: result.paginationtoken, pageSize: req.params.pageSize});
              }
              resolve({data:result.values, page: tableQuery.page, totalCount: result.paginationtoken !== undefined ? ((tableQuery.page + 1)*req.params.pageSize) + 1 : ((tableQuery.page + 1)*req.params.pageSize)});
            });
        }
  });
  
  useEffect(()=>{
    if(query.org && query.org.id===undefined){        
        return;
    }   
    const GetReq=() =>{
      return  {
       id: searched?searchName:'',
       paginationToken:pageToken,            
       params: query     
     };
     }
     let req = GetReq() ;
     if(req.params.timeFrameChanged)
     {
        setPageDataCache([]);
        tblRef.current.retry();
     }
     else if(req.params.searched || req.params.reload)
     {
        tblRef.current.retry();
     }
  // eslint-disable-next-line
  },[searched,searchName,timeFrameChanged,pageToken,query,GetService,tblRowCount])

  useEffect(() => {   
    if (searched && expandDetail  && searchName.length > 0) {      
        expandRow(tblRef, rowId);      
        return;     
    } 
    else {        
      collapseRow(tblRef);
    }
    const handleRowExpand = (event) => {
      handleExpandClick(event, tblRef);
    };
    window.addEventListener("mousedown", handleRowExpand);
    return () => {
      window.removeEventListener("mousedown", handleRowExpand);
    };
  }, [searched,query, tblRef, expandDetail,rowId,searchName]);
  const handler = (event) => {
    handleRowClick(event, tblRef);
  };
  const handleCollapse = (event) => {
    handleRowCollapse(event);
  };
  const detailPanels = props.hideDetail
  ? []
  : [
      (row) => ({
        disabled: !row.HasMoreResults,
        tooltip: row.HasMoreResults ? props.tooltiptext : null,
        icon: () => row.HasMoreResults ? <ExpandMoreTwoToneIcon onClick={handler} />:<></>,
        openIcon: () =>row.HasMoreResults ?<ExpandLessTwoToneIcon onClick={handleCollapse} />:<></>,
        render: (row) => {
          return (row.rowData.HasMoreResults?
            <div>
              <div className="districtDetailTable">
                <DynamoDetailTable
                  rowData={row.rowData}
                  syncKey={props.syncDetailKey}
                  columns={props.detailColumns}
                  showHeaders={props.showDetailHeaders}
                  DetailService={props.DetailService}
                  handleDialogOpen={props.handleDialogOpen}
                  dialogStatus={props.dialogStatus}
                  query={props.query}
                  isSchool={props.isSchool}
                />
              </div>
            </div>:<div></div>
          );
        },
      }),
    ];

let resultTbl =  (
    <MaterialTable
      localization={{
        header : {
          actions: ''
       },
        body: {
          emptyDataSourceMessage: (
            <DataNotFoundMessage timeoutError={timeoutError}/>
          ),
        },
      }}
      tableRef={tblRef}
      isLoading={!isLoaded}
      columns={props.columns}
      icons={tableIcons}
      options={{
        showTitle: false,
        sorting: undefined,
        maxColumnSort:0,
        toolbar: false,
        columnResizable:false,
        draggable:false,
        paging: paging,
        pageSize: tblRowCount,
        pageSizeOptions: [10, 20, 50],
        showFirstLastPageButtons: false,
        paginationType: 'stepped', 
        rowStyle: (x) => {        
          if (x.tableData.id % 2) {
            return { backgroundColor: "#f2f2f2" };
          }
        },     
        headerStyle: {
          backgroundColor: "#93c5cf",
          color: "black",
          textAlign: "center",
          fontWeight: 600,
        },
        actionsColumnIndex: -1
      }}
      data={ tableQuery =>
        new Promise((resolve, reject) => {
          const GetReq=() =>{
            return  {
             id: searched?searchName:'',
             paginationToken:pageToken,            
             params: query     
           };
           }
           let req = GetReq() ;
          resolve(setData(req, tableQuery))
        })
      }
      detailPanel={detailPanels}
      onPageChange={handlePageChange}
      actions={getRowActions({...props})}    
      onRowClick={(event, rowData, togglePanel) => {  if(event.clientX===0 && event.clientY===0){togglePanel()}}}        
    />
  );
  return resultTbl;
};

export default RenDynamoResultsTable;