import moment from 'moment';
export function formatTime(time,format) {
    return moment(time).format(format);
}
export function AddHours(time,hours){
    return moment(time).add(hours,'hours');
}

export function getStartDateLimit() {
    var d = new Date();
    d.setMonth(d.getMonth() - 12);
    return d;
}

export function last7Days() {
    var d = new Date();
    d.setDate(d.getDate() - 6);
    return d;
}

export function last24Hours() {
    var d = new Date();
    d.setHours(d.getHours() - 24);
    return d;
}
export function AddnDays(n){
    var d = new Date();
    d.setDate(d.getDate() + n);
    return d;
}
export function last12Months() {
    var d = new Date();
    d.setDate(d.getDate() - 365);
    return d;
}