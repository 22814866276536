import { useHistory } from "react-router-dom";

export default function ClickableCell(props){
  let history = useHistory(); 
  let {district,districtBid,districtId,schoolId,name,rid,isDetail,rpid,clientid,districtName,isDistrictName,channel,createdon,entityType,isName,myData}=props.data;
  let data={myData,district,districtBid,districtId,schoolId,name,rid,isDetail,rpid,clientid,districtName,isDistrictName,channel,createdon,entityType,isName};
  let redirect={pathname:"/student:"+rid, state:{data:{...data,id:rid}}};     
  let label=isName?name:rid||schoolId;

  if (channel ===  "REN"){
    let rowId = rpid == null ? clientid : rpid;
    redirect = {pathname: "/RenStudent:" + rowId, state: {data:{...data,id:rowId}}};
    label = isName ? rpid : isDetail ? clientid : isDistrictName ? districtName : createdon;
  }
  else if (channel === "NWEA-Dynamo") {
    redirect={pathname:"/DynamoStudent:" + schoolId, state: {data:{...data,id:schoolId,entityType:entityType}} };
  }

  const handleRowClick = (event) => { history.push(redirect); }
  return (<span onClick={handleRowClick}>{label}</span>)
}
