import BasePage from "./CommonPages/BasePage";
import { useState, useEffect } from "react";
import { Link,useHistory } from 'react-router-dom';
import { nweaDynamoService } from "../../Services/nweaDynamoService";
import SearchBar from "../Common/SearchBar";
import NweaDynamoResultsTable from "../Common/NweaDynamoResultsTable";
import {DynamoNweaStudentPageColumns, DynamoNweaStudentDetailsColumns } from "../Common/columns";
import {formatTime,last12Months} from '../../utils/dateutils';
import TimeFrameSelector from "../Common/CalendarPicker";

export default function DynamoStudentPage(props) {
  const [org, setOrg] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSchool, setisSchool] = useState(false);
  const [districtId, setDistrictId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const errorMessage="Please enter at least three characters for a valid search.";
  const [query, setQuery] = useState({
    searchName: "",
    StartDate:formatTime(last12Months(),"MM/DD/YYYY"),
    EndDate:formatTime(new Date(),"MM/DD/YYYY"),  
    valid:true,
    searched:false,
    reload:false,
    timeFrameChanged:false
  });
  const history = useHistory();
  
  useEffect(() => {
    if(props.location.state){
      setDistrictId(props.location.state.data.districtId);
      setSchoolId(props.location.state.data.schoolId);
      setOrg(props.location.state.data);
      setisSchool(props.location.state.data.isDetail);
      setIsLoaded(true);    
    }
    else{
      history.push('/');
    }

  }, [props.location.state,history]);

  const handleSearch = (value) => {
    setQuery({...query,searchName:value.searchName,valid:value.valid,searched:value.searched,reload:value.reload});
  };
  const validator = (value) => {
    return value?.length===0 || value?.trim().length>=3;
  };
 const handleDateSelection=(startDate, endDate,selected)=>{   
    let strtDt=formatTime(startDate,"MM/DD/YYYY");
    let endDt=formatTime(endDate,"MM/DD/YYYY");
    let timeFrameChanged = (query.StartDate!==strtDt)||(query.EndDate!==endDt);     
     setQuery({...query,StartDate:formatTime(startDate,"MM/DD/YYYY"),EndDate:formatTime(endDate,"MM/DD/YYYY"),timeFrameChanged:timeFrameChanged});
 }
 const BreadCrumb=()=>{
  
  const handleRowClick=(event)=>{    
    let bid=isSchool?org.districtBid:org.id;    
    history.push({pathname:"/",state:{id:bid}});
    event.preventDefault();
  }
  let district=<a href="/" target="_blank" onClick={handleRowClick}>{isSchool?org.district:org.name}</a>;
  let label=isSchool?<>&nbsp;&gt;&nbsp;{org.name}</>:<></>;
  return (<p><Link to="/">District Dashboard</Link>&nbsp;&gt;&nbsp;{district}{label}</p>)

}
  return (
    <BasePage>    
      <div label="NWEA (No Products)">
        {!isLoaded ? (
          <div className="ct-chart">Loading...</div>
        ) : (
          <div>
          <BreadCrumb/>
        
        <div className="banner-text">
          <p>
            NOTE: FOR INTERNAL USE ONLY. DO NOT SHARE INFORMATION OUTSIDE WNE
          </p>
            
        </div>
         <SearchBar
            onSearchClick={handleSearch}
            validator={validator}
            placeholderText="Search School/District by Student ID"
            validationErrorMessage={errorMessage}
        />
        <TimeFrameSelector 
          onTimeFrameChange={handleDateSelection}
          setDefaultValue="Last 12 Months"
        />

        <NweaDynamoResultsTable
          columns={DynamoNweaStudentPageColumns}
          detailColumns={DynamoNweaStudentDetailsColumns}
          showDetailHeaders={true}
          syncKey="Student"
          query={query}
          tooltiptext="Show Assessments"
          GetService={nweaDynamoService.getStudents}
          DetailService={nweaDynamoService.getResultsByOrgByStudentV4}
          DistrictId={districtId}
          SchoolId={schoolId}
        />

        </div>
        )}
      </div>
      <div label="Renaissance (No Products)">
        Not available for this School/District Code
      </div>
    </BasePage>
  );
}
