export interface IConfig {
    NWEAToken:string;
    NWEAAPI:string;
    OktaURL:string;
    OktaClientId:string;
    RENAISSANCEAPIToken:string;
    RENAISSANCEAPI:string;
    TimeoutInMinutes:number;    
    AssessmentAPI:string;
    AssessmentAPIToken:string;
    AssessmentAPISrc:string;
    AssessmentAPIV1:string;   
    HangfireAccessToken:string;
    RenaissanceDocumentation:string;
    NweaDocumentation:string;
    ReleaseNotes:string;
    SlackChannel:string;
    AssessmentAPIV4:string;
}

const config: IConfig = {
    NWEAToken:(window as any).APP_ENV.NWEAToken|| "",
    NWEAAPI:(window as any).APP_ENV.NWEAAPI|| "",
    OktaURL:(window as any).APP_ENV.OKTAURL||"",
    OktaClientId:(window as any).APP_ENV.OKTACLIENTID||"",
    RENAISSANCEAPIToken:(window as any).APP_ENV.RENAISSANCEToken|| "",
    RENAISSANCEAPI:(window as any).APP_ENV.RENAISSANCEAPI|| "",
    TimeoutInMinutes:60,
    AssessmentAPI:(window as any).APP_ENV.AssessmentAPI|| "", 
    AssessmentAPIToken:(window as any).APP_ENV.AssessmentAPIToken|| "", 
    AssessmentAPISrc:(window as any).APP_ENV.AssessmentAPISrc|| "aisui",
    AssessmentAPIV1:(window as any).APP_ENV.AssessmentAPIV1|| "",
    HangfireAccessToken:(window as any).APP_ENV.HangfireAccessToken||"",
    RenaissanceDocumentation:(window as any).APP_ENV.RenaissanceDocumentation||"",
    NweaDocumentation:(window as any).APP_ENV.NweaDocumentation||"",
    ReleaseNotes:(window as any).APP_ENV.ReleaseNotes||"",
    SlackChannel:(window as any).APP_ENV.SlackChannel||"",
    AssessmentAPIV4:(window as any).APP_ENV.AssessmentAPIV4|| ""
}

export default config;