import { useContext} from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {DataContext} from "../DataContext";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    title: {
      textAlign: "center",
      textDecorationLine: 'underline'
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  
const DialogTitle = withStyles(styles)((props) => {  
    const context=useContext(DataContext);
    const { action, classes, handleClose, ...other } = props;       
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6" className={classes.title}>
          {context.GetDialogTitle(action)}
        </Typography>        
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>       
      </MuiDialogTitle>
    );
  });

  export default function DialogTitleWithClose(props){ 
    return (<DialogTitle id="customized-dialog-title" {...props}></DialogTitle>);
  }