//Original Home.jsx wih okta
import DistrictPage from './Components/Pages/DistrictPage'
import { useOktaAuth } from '@okta/okta-react';
import { useIdleTimer } from 'react-idle-timer';
import config from "./config/baseconfig";
function Home() {
  const { oktaAuth,authState } = useOktaAuth();
  const hasLoggedIn=authState&&authState.isAuthenticated;
  const handleOnIdle = event => {    
    console.log('last active', getLastActiveTime())
    if (authState && authState.isAuthenticated) {
       oktaAuth.signOut();
    }
  }

  const handleOnActive = event => {}
  const handleOnAction = event => {}

  const {  getLastActiveTime } = useIdleTimer({
    timeout: 60000 * config.TimeoutInMinutes,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    crossTab: {
      emitOnAllTabs: true
    },
    debounce: 500
  })
  return (
    hasLoggedIn?
    <DistrictPage />    
    :<div className="warning"><h2>Not Logged In</h2></div>
     );

}
export default Home;
